import { useEffect } from "react"
import { useState } from "react"

async function getIp() {
  const res = await fetch("https://ip.luis.network")
  const data = await res.text();
  return {
    ip: data.split("\n")[0],
    location: data.split("\n")[2],
    asn: data.split("\n")[3],
  }
}

export function App() {
  const [ip, setIp] = useState("")
  const [asn, setAsn] = useState("")
  const [location, setLocation] = useState("")

  const [error, setError] = useState("")

  useEffect(() => {
    getIp()
      .then(({ ip, asn, location }) => {
        setIp(ip)
        setAsn(asn)
        setLocation(location)
      })
      .catch(e => {
        console.error(e);
        setError(e)
      })
  }, [])

  return (
    <div className="relative isolate overflow-hidden bg-gray-900 h-full ">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center animate__animated animate__fadeIn">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Not connected to the network.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Make sure you are connected to the Wi-Fi network and try again.
          </p>

          {/* Fixed in bottom of the page */}
          <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
            {ip && (
              <p className="text-center text-gray-300 mb-20 animate__animated animate__fadeIn animate__delay-1s">
                <span className="font-semibold">{ip}</span>
                <br />
                {asn && <>
                  <br />
                  <span className="text-sm">{asn}</span>
                </>}
                {location && <>
                  <br />
                  <span className="text-sm">{location}</span>
                </>}
              </p>
            )}
            {error && (
              <p className="text-center text-sm text-gray-300 mb-20 animate__fadeIn animate__delay-1s">
                Error: <span className="font-semibold">{error.message}</span>
              </p>
            )}
            <p className="text-center text-sm text-gray-300 mt-20">
              If you are away, you can connect using <a href="https://tailscale.com/" className="text-white">Tailscale</a>.
            </p>
            <div className="mt-6 mb-16 flex items-center justify-center gap-x-6">
              <a href="https://tailscale.com/download" className="text-sm font-semibold leading-6 text-white">
                Download <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[62rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] animate__animated animate__fadeIn"
        aria-hidden="true"
      >
        <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#C2185B" />
            <stop offset={1} stopColor="#AD1457" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
